import React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { getFranchises } from "api/lmpbp/franchises";
import { Accordion, Card, Container, Row, Col } from "react-bootstrap";
import SelectReact from "react-select";
import Loader from "components/common/loader/Loader";
import AddButton from "../custom-buttons/AddButton/AddButton";
import { getFranchiseWebsites, searchFranchiseWebsites } from "api/lmpbp/websites";
import PaginatedItems from "components/common/paginated/PaginatedItems";
import ConfirmationModal from "components/common/confirmation-modal/ConfirmationModal";
import TrashIconButton from "../custom-buttons/DeleteButton/DeleteButton";
import useDeleteFranchiseWebsite from "hooks/useDeleteFranchiseWesite";
import AddTacticWebsiteForm from "./AddTacticWebsiteForm";

function SearchByFranchise({
  selectedTemplate,
  activePage,
  setActivePage,
  itemsPerPage,
  setItemsPerPage
}) {
  const [showModal, setShowModal] = useState(false);
  const [franTacticWeb, setFranTacticWeb] = useState(null);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [addingTacticWebsite, setAddingTacticWebsite] = useState(false);

  const { mutate } = useDeleteFranchiseWebsite("get-franchise-websites");
  const franchises = useQuery("get-franchises", getFranchises);
  const franchisesOptions = franchises.data?.map(f =>
    ({ value: f, label: `${f.externalId} - ${f.name}` })
  );

  const { isFetching, data } = useQuery(
    ["get-franchise-websites", selectedFranchise?.value.id, itemsPerPage, activePage],
    () => searchFranchiseWebsites(selectedFranchise?.value.id || null, itemsPerPage, activePage)
  );

  const getFranchiseLabel = (franchise) => {
    return `${franchise.externalId} - ${franchise.name}`;
  }

  const modal =
    <ConfirmationModal
      show={showModal}
      type='none'
      headerType='warning'
      header='Confirm website removal from franchise'
      title='Are your sure you want to remove this website from this franchise?'
      message=<div>
        This action will permanently remove this website from this franchises
        {franTacticWeb?.parent.id === franTacticWeb?.franchise.id && franTacticWeb?.children.length > 0 ?
          <>
            &nbsp;and its children:
            <ul>
              {franTacticWeb?.children.map(f => <li key={f.id}>{getFranchiseLabel(f)}</li>)}
            </ul>
          </>
          : '. '
        }
        This action <strong>cannot</strong> be undone.
      </div>
      headerIconRight='fas fa-trash-alt'
      confirmBtnLabel='Remove'
      cancelBtnLabel='Cancel'
      showConfirmBtn={true}
      showCancelBtn={true}
      onConfirm={() => {
        for (const tactic of franTacticWeb.tactics) {
          mutate({ franchiseId: franTacticWeb.franchise.id, tacticId: tactic.id });
        }
        setShowModal(false);
        setFranTacticWeb(null);
      }}
      onClose={() => setShowModal(false)}
    />

  return (
    <Container>
      <Row>
        <Col>
          <i className="fa fa-building brand-primary-color" />
          <SelectReact
            placeholder={'Select Franchise'}
            isClearable={true}
            value={selectedFranchise}
            onChange={setSelectedFranchise}
            options={franchisesOptions}
            className="d-inline-block w-50 m-2"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {modal}
          <PaginatedItems
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            activePage={activePage}
            setActivePage={setActivePage}
            totalPages={data?.metadata?.totalPages}
            totalItems={data?.metadata?.totalItems}
          >
            {
              isFetching
                ? <Loader loadingMessage="searching franchises ..." />
                : <Accordion defaultActiveKey="0">
                  {data.data?.map((franchise, index) => (
                    <div key={index}>
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={index.toString()}
                          role="button">
                          {getFranchiseLabel(franchise)}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={index.toString()}>
                          <Card.Body className="website-card">
                            {franchise.tacticWebsites.map((tacticWebsite, index) => (
                              <Card key={index} className="mb-2">

                                <Card.Body>
                                  <Card.Title className="d-flex justify-content-between">
                                    {tacticWebsite.tactics.map(t => t.displayName).join(' & ')}
                                    <TrashIconButton
                                      onClick={() => {
                                        setFranTacticWeb({
                                          franchise: franchise,
                                          website: tacticWebsite.website,
                                          tactics: tacticWebsite.tactics,
                                          parent: tacticWebsite.parent,
                                          children: tacticWebsite.children,
                                        });
                                        setShowModal(true)
                                      }}
                                    />
                                  </Card.Title>
                                  <Card.Text>
                                    <span className="d-block">
                                      <a href={tacticWebsite.website.url}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {tacticWebsite.website.url}
                                      </a>
                                    </span>
                                    {tacticWebsite.parent.id === franchise.id
                                      ? <span className="badge badge-primary">Parent</span>
                                      : <span className="badge badge-secondary">
                                        Child of {tacticWebsite.parent.externalId}
                                      </span>
                                    }
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            ))}
                            <div className="d-flex justify-content-center">
                              {franchise.tacticWebsites.length < 2 && !addingTacticWebsite &&
                                <AddButton onClick={() => setAddingTacticWebsite(true)}>
                                  Add website to franchise tactic
                                </AddButton>
                              }
                              {addingTacticWebsite &&
                                <AddTacticWebsiteForm
                                  selectedTemplate={selectedTemplate}
                                  onClose={() => setAddingTacticWebsite(false)}
                                  franchise={franchise}
                                />}
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  ))
                  }
                </Accordion>
            }

          </PaginatedItems>
        </Col>
      </Row>
    </Container >
  );
}

export default SearchByFranchise;
